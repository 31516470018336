<template>
  <div>
    <v-dialog v-model="dialog" max-width="500" max-height="350" persistent>
      <v-card class="overflow-hidden">
        <v-row class="ma-0 ml-4">
          <span
            class="col-10 px-0 grey--text text--darken-4 text-h6 text-truncate"
            >{{ $t("scheduleTicket") }}</span
          >
          <v-btn
            class="col-2 my-auto"
            aria-label="cross"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider />
        <v-row no-gutters class="mx-4 mt-6 mb-4" justify="center">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="scheduleDate"
                :label="$t('scheduleDate')"
                persistent-hint
                outlined
                dense
                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"
                :error-messages="dateFormattedErrors"
                :hide-details="dateFormattedErrors.length === 0"
                v-on="on"
                @input="$v.scheduleDate.$touch()"
                @change="emptyRevisedDate"
                @blur="$v.scheduleDate.$touch()"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              :min="new Date().toISOString().substr(0, 10)"
              @change="emptyRevisedDate"
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-row>
        <v-row no-gutters class="mx-4 mt-2 mb-4" justify="center">
          <v-menu
            ref="menu"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="scheduleTime"
                :label="$t('time')"
                format="ampm"
                prepend-inner-icon="mdi-clock-time-four-outline"
                persistent-hint
                outlined
                dense
                v-bind="attrs"
                :error-messages="timeFormattedErrors"
                :hide-details="timeFormattedErrors.length === 0"
                v-on="on"
                @input="$v.scheduleTime.$touch()"
                @blur="$v.scheduleTime.$touch()"
                readonly
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="menu2"
              v-model="time"
              :menu-props="{ bottom: true, offsetY: true }"
              full-width
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(time)"
                :disabled="isPastTimeSelected"
              >
                OK
              </v-btn>
            </v-time-picker>
          </v-menu>
        </v-row>
        <v-row no-gutters class="mx-4 mt-6 mb-4" justify="center">
          <v-menu
            ref="menu3"
            v-model="menu3"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="revisedDate"
                :label="$t('revisedDate')"
                persistent-hint
                outlined
                dense
                :disabled="date === ''"
                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"
                :error-messages="revisedDateFormattedErrors"
                :hide-details="revisedDateFormattedErrors.length === 0"
                v-on="on"
                @input="$v.revisedDate.$touch()"
                @blur="$v.revisedDate.$touch()"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="revDate"
              no-title
              :min="date ? getNowDate : ''"
              :max="date ? getEndDate : ''"
              :allowed-dates="allowedDates"
              @input="menu3 = false"
            ></v-date-picker>
          </v-menu>
        </v-row>
        <v-row class="mb-2">
          <v-divider></v-divider>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary text-none rounded-lg elevation-0"
            outlined
            class="mr-2"
            width="90"
            @click="closeDialog"
          >
            {{ $t("cancel") }}
          </v-btn>

          <v-btn
            color="primary text-none rounded-lg elevation-0"
            width="160"
            depressed
            :disabled="$v.$invalid"
            @click="scheduleTickeClicked"
            ><span class="white--text text-none">{{
              $t("scheduleTicket")
            }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import moment from "moment";
export default {
  mixins: [validationMixin],
  validations() {
    return {
      scheduleDate: { required },
      revisedDate: { required },
      scheduleTime: { required },
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      center: this.$store.state.Ticket.ticketData.center,
      scheduleDate: "",
      revisedDate: "",
      menu1: false,
      date: "",
      revDate: "",
      time: null,
      scheduleTime: null,
      menu2: false,
      menu3: false,
      startDate: "",
    };
  },
  computed: {
    isPastTimeSelected() {
      if (!this.scheduleTime) return false;

      const now = new Date();
      const selectedTime = new Date(this.scheduleDate);
      const [time, ampm] = this.scheduleTime.split(" ");
      const [hours, minutes] = time.split(":");
      let adjustedHours = parseInt(hours);
      if (ampm.toLowerCase() === "pm" && adjustedHours !== 12) {
        adjustedHours += 12;
      } else if (ampm.toLowerCase() === "am" && adjustedHours === 12) {
        adjustedHours = 0;
      }

      selectedTime.setHours(adjustedHours, minutes, 0, 0);

      return selectedTime < now;
    },
    dateFormattedErrors() {
      const errors = [];
      if (!this.$v.scheduleDate.$dirty) return errors;
      !this.$v.scheduleDate.required &&
        errors.push(this.$t("scheduledDateRequiredError"));
      return errors;
    },
    revisedDateFormattedErrors() {
      const errors = [];
      if (!this.$v.revisedDate.$dirty) return errors;
      !this.$v.revisedDate.required &&
        errors.push(this.$t("revisedDateRequiredError"));
      return errors;
    },
    timeFormattedErrors() {
      const errors = [];
      if (!this.$v.scheduleTime.$dirty) return errors;
      !this.$v.scheduleTime.required &&
        errors.push(this.$t("scheduledTimeRequiredError"));
      return errors;
    },
    getNowDate() {
      var tempBeginDate = new Date(this.date);
      var beginDate =
        this.center === "NCOCC"
          ? new Date(tempBeginDate.setDate(tempBeginDate.getDate() + 1))
          : new Date(tempBeginDate.setDate(tempBeginDate.getDate() + 2));
      while (!this.disableWeekends(beginDate)) {
        beginDate.setDate(beginDate.getDate() + 1);
      }
      if (this.center === "USAS") {
        this.setStartDate(new Date(this.date));
      } else if (this.center === "NCOCC") {
        this.setStartDate(new Date(this.scheduleDate));
      } else {
        this.setStartDate(beginDate);
      }
      return beginDate.toISOString().slice(0, 10);
    },
    getEndDate() {
      var endDate = "";
      let tempPickerDate = new Date(this.date);
      if (this.center === "JULIE") {
        if (this.startDate) {
          endDate = new Date(
            this.startDate.setDate(this.startDate.getDate() + 6),
          );
        }
      } else if (this.center === "OUPS") {
        let count = 1,
          noOfDaysToAdd = 10;
        if (this.startDate) {
          let beginDate = this.startDate;
          let tempDate = "";
          while (count < noOfDaysToAdd) {
            tempDate = new Date(beginDate.setDate(beginDate.getDate() + 1));
            if (tempDate.getDay() != 0 && tempDate.getDay() != 6) {
              count++;
            }
          }
          endDate = new Date(tempDate.setDate(tempDate.getDate()));
        }
      } else if (this.center === "UTAH") {
        endDate = new Date(
          tempPickerDate.setDate(tempPickerDate.getDate() + 14),
        );
        let countDays = 3;
        while (countDays > 0) {
          if (this.disableWeekends(endDate)) countDays--;
          endDate.setDate(endDate.getDate() - 1);
        }
      } else if (this.center === "UNCC") {
        let count = 1,
          noOfDaysToAdd = 14;
        if (this.startDate) {
          let beginDate = this.startDate;
          let tempDate = "";
          while (count < noOfDaysToAdd) {
            tempDate = new Date(beginDate.setDate(beginDate.getDate() + 1));
            if (tempDate.getDay() != 0 && tempDate.getDay() != 6) {
              count++;
            }
          }
          endDate = new Date(tempDate.setDate(tempDate.getDate()));
        }
      } else if (this.center === "VUPS") {
        let count = 1,
          noOfDaysToAdd = 10;
        if (this.startDate) {
          let beginDate = this.startDate;
          let tempDate = "";
          while (count < noOfDaysToAdd) {
            tempDate = new Date(beginDate.setDate(beginDate.getDate() + 1));
            if (tempDate.getDay() != 0 && tempDate.getDay() != 6) {
              count++;
            }
          }
          endDate = new Date(tempDate.setDate(tempDate.getDate()));
        }
      } else if (this.center === "USAS") {
        if (this.startDate) {
          let tempStartDate = new Date(this.date);
          endDate = new Date(
            this.startDate.setDate(this.startDate.getDate() + 14),
          );
        }
      } else if (this.center === "NCOCC") {
        let noOfDaysToAdd = 11;

        if (this.startDate) {
          let beginDate = new Date();
          beginDate.setDate(this.startDate.getDate() + 1);

          while (this.isWeekend(beginDate)) {
            beginDate.setDate(beginDate.getDate() + 1);
          }

          while (noOfDaysToAdd > 0) {
            if (!this.isWeekend(beginDate)) {
              noOfDaysToAdd--;
            }

            endDate = new Date(beginDate.setDate(beginDate.getDate() + 1));
          }

          while (this.isWeekend(endDate)) {
            endDate.setDate(endDate.getDate() + 1);
          }
        }
      }

      return endDate.toISOString().slice(0, 10);
    },
  },
  watch: {
    date(val) {
      this.scheduleDate = this.formatDate(this.date);
    },
    revDate(val) {
      this.revisedDate = this.formatDate(this.revDate);
    },
    time(val) {
      this.scheduleTime = this.formatTime(this.time);
    },
  },
  created() {
    this.scheduleDate = this.$store.state.Ticket.scheduleDetails.schedule_date;
    this.time = this.$store.state.Ticket.scheduleDetails.schedule_time;
    this.revisedDate = this.$store.state.Ticket.scheduleDetails.revised_date;
  },
  methods: {
    isWeekend(date) {
      const day = new Date(date).getDay();
      return day === 0 || day === 6; // Sunday (0) and Saturday (6) are weekends
    },
    disableWeekends(date) {
      const day = new Date(date).getDay();
      return !(day === 0 || day === 6);
    },
    setStartDate(tempDate) {
      this.startDate = tempDate;
    },
    scheduleTickeClicked() {
      let scheduleObject = {};
      scheduleObject = {
        schedule_date: this.scheduleDate,
        schedule_time: this.time,
        revised_date: this.revisedDate,
      };
      this.$emit("scheduleTicket", scheduleObject);
      this.closeDialog();
    },
    closeDialog() {
      if (!this.$store.state.Ticket.isEdit) {
        this.scheduleDate = "";
        this.revisedDate = "";
        this.scheduleTime = "";
        this.date = "";
        this.revDate = "";
        this.time = null;
        this.$v.scheduleDate.$reset();
        this.$v.scheduleTime.$reset();
        this.$v.revisedDate.$reset();
      }
      this.$emit("closeDialog");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    formatTime(time) {
      if (!time) return null;
      let [hours, minute] = time.split(":");
      let AmOrPm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;
      return `${hours}:${minute} ${AmOrPm}`;
    },
    emptyRevisedDate() {
      this.revisedDate = "";
      this.revDate = "";
      this.startDate = "";
    },
    allowedDates(date) {
      var dayOfWeek = moment(date).day();
      if (this.center === "OUPS" || this.center === "VUPS")
        return !(dayOfWeek == 0 || dayOfWeek == 6);
      else return [0, 1, 2, 3, 4, 5, 6];
    },
  },
};
</script>

<style lang="scss" scoped></style>
