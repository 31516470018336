var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500", "max-height": "350", persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "overflow-hidden" },
            [
              _c(
                "v-row",
                { staticClass: "ma-0 ml-4" },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "col-10 px-0 grey--text text--darken-4 text-h6 text-truncate",
                    },
                    [_vm._v(_vm._s(_vm.$t("scheduleTicket")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "col-2 my-auto",
                      attrs: { "aria-label": "cross", icon: "" },
                      on: { click: _vm.closeDialog },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-6 mb-4",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c(
                    "v-menu",
                    {
                      ref: "menu1",
                      attrs: {
                        "close-on-content-click": false,
                        transition: "scale-transition",
                        "offset-y": "",
                        "max-width": "290px",
                        "min-width": "auto",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        label: _vm.$t("scheduleDate"),
                                        "persistent-hint": "",
                                        outlined: "",
                                        dense: "",
                                        "prepend-inner-icon": "mdi-calendar",
                                        "error-messages":
                                          _vm.dateFormattedErrors,
                                        "hide-details":
                                          _vm.dateFormattedErrors.length === 0,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.$v.scheduleDate.$touch()
                                        },
                                        change: _vm.emptyRevisedDate,
                                        blur: function ($event) {
                                          return _vm.$v.scheduleDate.$touch()
                                        },
                                      },
                                      model: {
                                        value: _vm.scheduleDate,
                                        callback: function ($$v) {
                                          _vm.scheduleDate = $$v
                                        },
                                        expression: "scheduleDate",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.menu1,
                        callback: function ($$v) {
                          _vm.menu1 = $$v
                        },
                        expression: "menu1",
                      },
                    },
                    [
                      _c("v-date-picker", {
                        attrs: {
                          "no-title": "",
                          min: new Date().toISOString().substr(0, 10),
                        },
                        on: {
                          change: _vm.emptyRevisedDate,
                          input: function ($event) {
                            _vm.menu1 = false
                          },
                        },
                        model: {
                          value: _vm.date,
                          callback: function ($$v) {
                            _vm.date = $$v
                          },
                          expression: "date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-2 mb-4",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c(
                    "v-menu",
                    {
                      ref: "menu",
                      attrs: {
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        "return-value": _vm.time,
                        transition: "scale-transition",
                        "offset-y": "",
                        "max-width": "290px",
                        "min-width": "290px",
                      },
                      on: {
                        "update:returnValue": function ($event) {
                          _vm.time = $event
                        },
                        "update:return-value": function ($event) {
                          _vm.time = $event
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        label: _vm.$t("time"),
                                        format: "ampm",
                                        "prepend-inner-icon":
                                          "mdi-clock-time-four-outline",
                                        "persistent-hint": "",
                                        outlined: "",
                                        dense: "",
                                        "error-messages":
                                          _vm.timeFormattedErrors,
                                        "hide-details":
                                          _vm.timeFormattedErrors.length === 0,
                                        readonly: "",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.$v.scheduleTime.$touch()
                                        },
                                        blur: function ($event) {
                                          return _vm.$v.scheduleTime.$touch()
                                        },
                                      },
                                      model: {
                                        value: _vm.scheduleTime,
                                        callback: function ($$v) {
                                          _vm.scheduleTime = $$v
                                        },
                                        expression: "scheduleTime",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.menu2,
                        callback: function ($$v) {
                          _vm.menu2 = $$v
                        },
                        expression: "menu2",
                      },
                    },
                    [
                      _vm.menu2
                        ? _c(
                            "v-time-picker",
                            {
                              attrs: {
                                "menu-props": { bottom: true, offsetY: true },
                                "full-width": "",
                              },
                              model: {
                                value: _vm.time,
                                callback: function ($$v) {
                                  _vm.time = $$v
                                },
                                expression: "time",
                              },
                            },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.menu2 = false
                                    },
                                  },
                                },
                                [_vm._v(" Cancel ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    color: "primary",
                                    disabled: _vm.isPastTimeSelected,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$refs.menu.save(_vm.time)
                                    },
                                  },
                                },
                                [_vm._v(" OK ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-6 mb-4",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c(
                    "v-menu",
                    {
                      ref: "menu3",
                      attrs: {
                        "close-on-content-click": false,
                        transition: "scale-transition",
                        "offset-y": "",
                        "max-width": "290px",
                        "min-width": "auto",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        label: _vm.$t("revisedDate"),
                                        "persistent-hint": "",
                                        outlined: "",
                                        dense: "",
                                        disabled: _vm.date === "",
                                        "prepend-inner-icon": "mdi-calendar",
                                        "error-messages":
                                          _vm.revisedDateFormattedErrors,
                                        "hide-details":
                                          _vm.revisedDateFormattedErrors
                                            .length === 0,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.$v.revisedDate.$touch()
                                        },
                                        blur: function ($event) {
                                          return _vm.$v.revisedDate.$touch()
                                        },
                                      },
                                      model: {
                                        value: _vm.revisedDate,
                                        callback: function ($$v) {
                                          _vm.revisedDate = $$v
                                        },
                                        expression: "revisedDate",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.menu3,
                        callback: function ($$v) {
                          _vm.menu3 = $$v
                        },
                        expression: "menu3",
                      },
                    },
                    [
                      _c("v-date-picker", {
                        attrs: {
                          "no-title": "",
                          min: _vm.date ? _vm.getNowDate : "",
                          max: _vm.date ? _vm.getEndDate : "",
                          "allowed-dates": _vm.allowedDates,
                        },
                        on: {
                          input: function ($event) {
                            _vm.menu3 = false
                          },
                        },
                        model: {
                          value: _vm.revDate,
                          callback: function ($$v) {
                            _vm.revDate = $$v
                          },
                          expression: "revDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-row", { staticClass: "mb-2" }, [_c("v-divider")], 1),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        color: "primary text-none rounded-lg elevation-0",
                        outlined: "",
                        width: "90",
                      },
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary text-none rounded-lg elevation-0",
                        width: "160",
                        depressed: "",
                        disabled: _vm.$v.$invalid,
                      },
                      on: { click: _vm.scheduleTickeClicked },
                    },
                    [
                      _c("span", { staticClass: "white--text text-none" }, [
                        _vm._v(_vm._s(_vm.$t("scheduleTicket"))),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }